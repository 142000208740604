import React from "react"
import SEO from "../../components/seo"
import HeroLandingPage from "../../components/pages/shared/hero/landing"
import FeaturesComponent from "../../components/pages/main/landing-features"
import PricingComponent from "../../components/pages/main/detail-pricing"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import Banner from "../../components/pages/main/banner"
import Cards from "../../components/pages/main/cards"
import MainPageInfo from "../../components/pages/main/info"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';
import CreativeschoolAccordionComponent from "../../components/pages/creativeschool/accordion"

const KinderCrativePage = (props) => {
        const crumbs = [
                { name: 'Главная', url: '/'},
                { name: 'Творчество', url: ''}
        ];
        return (
          <Layout headerColor={"#6D20D2"} page={"landing_creativeschool"}>
                  <SEO title="CRM-система для танцевальных студий"
                       description="CRM для танцевальных школ и студий. Автоматизация учета посещаемости, приема оплат и контроля педагогов. Демо-доступ 10 дней бесплатно."
                       url={"https://paraplancrm.ru" + props.location.pathname}
                       ogImage={"https://paraplancrm.ru/images/seo/creativeschool.png"}
                  />
                  <HeroLandingPage
                    title='CRM-система учета'
                    subtitle="для танцевальных студий"
                    idUnderBlock="#features"
                    offsetHeader={80}
                    list={[
                            '<b>Прозрачное</b> управление бизнес-процессами.',
                            '<b>Хранение истории</b> взаимодействия с клиентом.',
                            'Актуальные данные <b>в режиме онлайн</b>.',                            
                    ]} />
                  <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
                  <FeaturesComponent title='Все, что нужно' subtitle="Не только инструменты для работы с заявками и контроля отдела продаж" />
                  <Discount />
                  <MainPageInfo
                    title='Работа с CRM - это переход на новый уровень'
                    subtitle='Параплан создан специально для ведения учета клиентов и продаж школ искусств, танцев, вокала, рисования, студий актерского мастерства, творческих курсов и мастер-классов.' />
                  <Cards />
                  <Banner />
                  <PricingComponent title='Тарифы' mainPage={true} mobileMenu={false} />
                  <ContactFormComponent />
                  <CreativeschoolAccordionComponent/>
          </Layout>
        )
}

export default KinderCrativePage
